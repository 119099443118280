import "./styles/index.css";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import SmartFilter from "@/components/common/smartFilter";
import usePartnersGetQuery from "../__internals__/hooks/usePartnersGetQuery";
import { isEmpty } from "../__internals__/assertIfEmpty";
import EmptyScreen from "@/components/common/emptyScreen";
import TwinValue from "@/components/common/twinValue";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { formatDateTime, formatMetaData, formatStatus } from "@/utils/helper/Helper";
import { detectStatus, formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";

export const PartnersSettlement = () => {
  const { tableData, SmartFilterProps, refetch, isRedacting, combinedLoading } =
    usePartnersGetQuery<PartnersSettlements>("getSettlements");


  return (
    <DashboardLayout>
      <PageLayout pageTitle="Settlement">
        <div className="bb-kickbacks">
          <SmartFilter {...SmartFilterProps} searchTitle="Search settlements" />
          {isEmpty(tableData) ? (
            <EmptyScreen loading={combinedLoading || isRedacting} />
          ) : (
            <RavenTable
              action={false}
              className="table__main"
              headerList={[
                "MERCHANT DETAILS",
                "BANK DETAILS",
                "AMOUNT",
                "TYPE",
                "DATE",
                "STATUS",
                "",
              ]}
            >
              {tableData.map((data, index) => {
                const meta = formatMetaData(data?.meta_data);
                return(
                <RavenTableRow
                  key={index}
                  one={<TwinValue one={Util.safeValue(data.poseidon_business_name)} two={`Partner: ${data.partner_name}`} />}
                  two={<TwinValue one={Util.safeValue(meta?.account_name)} two={Util.safeValue(meta?.account_number)} />}
                  three={formatNumberToCurrency(data?.amount)}
                  four={Util.safeValue(data?.type)}
                  five={formatDateTime(data?.created_at)}
                  six={formatStatus(detectStatus(1))}
                />
              )})}
            </RavenTable>
          )}
        </div>
      </PageLayout>
    </DashboardLayout>
  );
};