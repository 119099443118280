import "./styles/index.css";
import { DashboardInfoCard } from "../../../components/common/dashboardInfo";
import DashboardLayout from "../../../components/layouts/dashboard/dashboardLayout";
import TabComponent from "@/components/common/Tabs";
import { RavenInputField, RavenNumberFormat } from "@ravenpay/raven-bank-ui";
import { OverviewLineChart } from "@/components/charts/overview-chart";
import usePartnersGetQuery from "../__internals__/hooks/usePartnersGetQuery";
import { isEmpty } from "../__internals__/assertIfEmpty";
import EmptyScreen from "@/components/common/emptyScreen";
import Redacted from "@/components/common/shimmer/redact";
import { useEffect, useState } from "react";
import { months } from "@/components/common/graphFilter/months";

const Tabs = ["Partners", "Merchants", "Transaction", "Device Request"];

export const reactSelectStyle = {
  control: (base: any, state: any) => ({
    ...base,
    border: state.isFocused ? "0.1rem solid #6F6F6F" : "0.1rem solid #6F6F6F",
    // backgroundColor: state.isSelected ? "#6F6F6F" : "white",
    boxShadow: state.isFocused ? "0.1rem solid #6F6F6F" : 0,
    "&:hover": {
      // border: state.isFocused ? 0 : 0
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "rgba(204, 204, 204, .3)" : "white",
    color: state.isSelected ? "#020202" : "#020202",
  }),
};

function PartnersOverview() {
  const [selectedTab, setSelectedTab] = useState(Tabs[0]);
  const { tableData, refetch, isRedacting, combinedLoading } =
    usePartnersGetQuery<PartnersDashboardOverview>("getOverview", {
      pageSize: "",
      period: "annual",
    });

  const overviewData = tableData as unknown as PartnersDashboardOverview;

  const partnersData = usePartnersGetQuery<PartnersOverviewChart>("getOverviewPartners", {
    pageSize: "",
    period: "annual",
    date: "2024-09-01",
  });
  const merchantsData = usePartnersGetQuery<PartnersOverviewChart>(
    "getOverviewMerchants",
    {
      pageSize: "",
      period: "annual",
      date: "2024-09-01",
    }
  );
  const transactionsData = usePartnersGetQuery<PartnersOverviewChart>(
    "getOverviewTransactions",
    {
      pageSize: "",
      period: "annual",
      date: "2024-09-01",
    }
  );
  const devicesData = usePartnersGetQuery<PartnersOverviewChart>(
    "getOverviewDeviceRequests",
    {
      pageSize: "",
      period: "annual",
      date: "2024-09-01",
    }
  );

  const [merchants, setMerchants] = useState<number[]>([]);
  const [transactions, setTransactions] = useState<number[]>([]);
  const [devices, setDevices] = useState<number[]>([]);

  const dashboardData = [
    {
      title: "Total Partners",
      value: RavenNumberFormat(overviewData?.totalPartners ?? 0, {
        convertToNumber: true,
      }),
      // stats: `+6000 This month`,
      isPositive: true,
    },
    {
      title: "Total Merchants",
      value: RavenNumberFormat(overviewData?.totalMerchants ?? 0, {
        convertToNumber: true,
      }),
      // stats: `+${overviewData?.merchantsPerMonth} This month`,
      isPositive: true,
    },
    {
      title: "Total Transaction Count",
      value: RavenNumberFormat(overviewData?.totalTransactionCount ?? 0, {
        convertToNumber: true,
      }),
      // stats: `+${overviewData?.monthlyTransactionCount} This month`,
      isPositive: true,
    },

    {
      title: "Total Transaction Volume",
      value: RavenNumberFormat(overviewData?.totalTransactionVolume, {
        numberAbbreviate: true,
      }),
    },
    {
      title: "Total Terminals",
      value: RavenNumberFormat(overviewData?.totalTerminalCount ?? 0, {
        numberAbbreviate: false,
        convertToNumber: true,
      }),
      // stats: "+8% This month",
      isPositive: true,
    },
    {
      title: "Device Requests",
      value: RavenNumberFormat(overviewData?.totalDeviceRequestCount ?? 0, {
        numberAbbreviate: false,
        convertToNumber: true,
      }),
      // stats: "+8% This month",
      isPositive: true,
    },
    {
      title: "Collection Count",
      value: RavenNumberFormat(overviewData?.totalCollectionCount ?? 0, {
        numberAbbreviate: false,
        convertToNumber: true,
      }),
      // stats: "+8% This month",
      isPositive: true,
    },
    {
      title: "Total Collection Balance",
      value: RavenNumberFormat(overviewData?.totalCollectionBalance ?? 0, {
        numberAbbreviate: true,
      }),
      // stats: "+8% This month",
      isPositive: true,
    },
  ];

  const partnersGraph = partnersData?.data as unknown as PartnersOverviewChart;
  const merchantsGraph = merchantsData?.data as unknown as PartnersOverviewChart;
  const transactionGraph = transactionsData?.data as unknown as PartnersOverviewChart;
  const deviceGraph = transactionsData?.data as unknown as PartnersOverviewChart;

  return (
    <DashboardLayout>
      <main className="buggies-business-dashboard">
        <h1 className="page-title text-white-black">Overview</h1>
        <DashboardInfoCard.Wrapper className="highlighted-content-wrapper">
          {dashboardData.map((ele) =>
            isRedacting ? (
              <Redacted.Table />
            ) : (
              <DashboardInfoCard key={ele.title} {...ele} />
            )
          )}
        </DashboardInfoCard.Wrapper>

        <div className="buggies-business-dashboard__chart-area highlighted-content-wrapper">
          <TabComponent
            tabs={Tabs}
            defaultValue={Tabs[0]}
            onTabValueChange={(e) => setSelectedTab(e as any)}
          >
            <TabComponent.Tabs tabs={Tabs} />

            <div className="buggies-business-dashboard__chart-content bdr-eee-333">
              <div hidden className="buggies-business-dashboard__select-area">
                <RavenInputField
                  selectOption={[
                    { label: "Yearly", value: "yearly" },
                    { label: "Monthly", value: "monthly" },
                    { label: "Weekly", value: "weekly" },
                    { label: "Daily", value: "daily" },
                  ]}
                  type="select"
                  color="black-light"
                  selectStyles={reactSelectStyle}
                  placeholder="Yearly"
                />
              </div>
              <div className="buggies-business-dashboard__chart">
                {combinedLoading ? (
                  <EmptyScreen loading={combinedLoading} />
                ) : (
                  <OverviewLineChart
                    data={
                      selectedTab.includes("Partners")
                        ? processGraphData(partnersGraph).data
                        : selectedTab.includes("Merchants")
                        ? processGraphData(merchantsGraph).data
                        : selectedTab.includes("Transaction")
                        ? processGraphData(transactionGraph).data
                        : processGraphData(deviceGraph).data
                    }
                  />
                )}
              </div>
            </div>
          </TabComponent>
        </div>
      </main>
    </DashboardLayout>
  );
}

export default PartnersOverview;

function processGraphData(graphData: PartnersOverviewChart) {
  let labels: SN[] = [];
  let data: SN[] = [];
  graphData?.breakdown?.map((item, i) => {
    labels.push(months[item.month]);
    data.push(item.count);
  });

  return { data, labels };
}
